/*
 This file is part of GNU Taler
 (C) 2021-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

import {
  AbsoluteTime,
  Codec,
  buildCodecForObject,
  codecForAbsoluteTime,
  codecForBoolean,
  codecForConstString,
  codecForEither,
} from "@gnu-taler/taler-util";
import { buildStorageKey, useLocalStorage } from "@gnu-taler/web-util/browser";

export interface Preferences {
  advanceOrderMode: boolean;
  hideKycUntil: AbsoluteTime;
  hideMissingAccountUntil: AbsoluteTime;
  dateFormat: "ymd" | "dmy" | "mdy";
}

const defaultSettings: Preferences = {
  advanceOrderMode: false,
  hideKycUntil: AbsoluteTime.never(),
  hideMissingAccountUntil: AbsoluteTime.never(),
  dateFormat: "ymd",
};

export const codecForPreferences = (): Codec<Preferences> =>
  buildCodecForObject<Preferences>()
    .property("advanceOrderMode", codecForBoolean())
    .property("hideKycUntil", codecForAbsoluteTime)
    .property("hideMissingAccountUntil", codecForAbsoluteTime)
    .property(
      "dateFormat",
      codecForEither(
        codecForConstString("ymd"),
        codecForConstString("dmy"),
        codecForConstString("mdy"),
      ),
    )
    .build("Preferences");

const PREFERENCES_KEY = buildStorageKey(
  "merchant-preferences",
  codecForPreferences(),
);

export function usePreference(): [
  Readonly<Preferences>,
  <T extends keyof Preferences>(key: T, value: Preferences[T]) => void,
  (s: Preferences) => void,
] {
  const { value, update } = useLocalStorage(PREFERENCES_KEY, defaultSettings);
  function updateField<T extends keyof Preferences>(k: T, v: Preferences[T]) {
    const newValue = { ...value, [k]: v };
    update(newValue);
  }

  return [value, updateField, update];
}

export function dateFormatForSettings(s: Preferences): string {
  switch (s.dateFormat) {
    case "ymd":
      return "yyyy/MM/dd";
    case "dmy":
      return "dd/MM/yyyy";
    case "mdy":
      return "MM/dd/yyyy";
  }
}

export function datetimeFormatForSettings(s: Preferences): string {
  return dateFormatForSettings(s) + " HH:mm:ss";
}
