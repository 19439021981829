/*
 This file is part of GNU Taler
 (C) 2021-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

/**
 *
 * @author Sebastian Javier Marchano (sebasjm)
 */

import { TalerMerchantApi } from "@gnu-taler/taler-util";
import { h, VNode } from "preact";
import { CardTable } from "./Table.js";

export interface Props {
  devices: TalerMerchantApi.BankAccountSummaryEntry[];
  // onLoadMoreBefore?: () => void;
  // onLoadMoreAfter?: () => void;
  onCreate: () => void;
  onDelete: (e: TalerMerchantApi.BankAccountSummaryEntry) => void;
  onSelect: (e: TalerMerchantApi.BankAccountSummaryEntry) => void;
}

export function ListPage({
  devices,
  onCreate,
  onDelete,
  onSelect,
  // onLoadMoreBefore,
  // onLoadMoreAfter,
}: Props): VNode {

  return (
    <section class="section is-main-section">
      <CardTable
        accounts={devices.map((o) => ({
          ...o,
          id: String(o.h_wire),
        }))}
        onCreate={onCreate}
        onDelete={onDelete}
        onSelect={onSelect}
        // onLoadMoreBefore={onLoadMoreBefore}
        // hasMoreBefore={!onLoadMoreBefore}
        // onLoadMoreAfter={onLoadMoreAfter}
        // hasMoreAfter={!onLoadMoreAfter}
      />
    </section>
  );
}
