/*
 This file is part of GNU Taler
 (C) 2021-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

/**
 *
 * @author Sebastian Javier Marchano (sebasjm)
 */

import { TalerMerchantApi } from "@gnu-taler/taler-util";
import { useTranslationContext } from "@gnu-taler/web-util/browser";
import { Fragment, h, VNode } from "preact";
import { useState } from "preact/hooks";
import { AsyncButton } from "../../../../components/exception/AsyncButton.js";
import {
  FormErrors,
  FormProvider,
} from "../../../../components/form/FormProvider.js";
import { Input } from "../../../../components/form/Input.js";
import { InputPaytoForm } from "../../../../components/form/InputPaytoForm.js";
import { InputSelector } from "../../../../components/form/InputSelector.js";
import { ImportingAccountModal } from "../../../../components/modal/index.js";
import { undefinedIfEmpty } from "../../../../utils/table.js";
import { safeConvertURL } from "../update/UpdatePage.js";

type Entity = TalerMerchantApi.AccountAddDetails & { repeatPassword: string };

interface Props {
  onCreate: (d: TalerMerchantApi.AccountAddDetails) => Promise<void>;
  onBack?: () => void;
}

const accountAuthType = ["none", "basic"];

export function CreatePage({ onCreate, onBack }: Props): VNode {
  const { i18n } = useTranslationContext();

  const [importing, setImporting] = useState(false);
  const [state, setState] = useState<Partial<Entity>>({});
  const facadeURL = safeConvertURL(state.credit_facade_url);
  const errors: FormErrors<Entity> = {
    payto_uri: !state.payto_uri ? i18n.str`required` : undefined,

    credit_facade_credentials: !state.credit_facade_credentials
      ? undefined
      : undefinedIfEmpty({
          username:
            state.credit_facade_credentials.type === "basic" &&
            !state.credit_facade_credentials.username
              ? i18n.str`required`
              : undefined,
          password:
            state.credit_facade_credentials.type === "basic" &&
            !state.credit_facade_credentials.password
              ? i18n.str`required`
              : undefined,
        }),
    credit_facade_url: !state.credit_facade_url
      ? undefined
      : !facadeURL
        ? i18n.str`Invalid url`
        : !facadeURL.href.endsWith("/")
          ? i18n.str`URL should end with a '/'`
          : facadeURL.searchParams.size > 0
            ? i18n.str`URL should not contain params`
            : facadeURL.hash
              ? i18n.str`URL should not hash param`
              : undefined,
    repeatPassword: !state.credit_facade_credentials
      ? undefined
      : state.credit_facade_credentials.type === "basic" &&
          (!state.credit_facade_credentials.password ||
            state.credit_facade_credentials.password !== state.repeatPassword)
        ? i18n.str`is not the same`
        : undefined,
  };

  const hasErrors = Object.keys(errors).some(
    (k) => (errors as Record<string, unknown>)[k] !== undefined,
  );

  const submitForm = () => {
    if (hasErrors) return Promise.reject();
    const credit_facade_url = !state.credit_facade_url
      ? undefined
      : facadeURL?.href;
    const credit_facade_credentials:
      | TalerMerchantApi.FacadeCredentials
      | undefined =
      credit_facade_url == undefined
        ? undefined
        : state.credit_facade_credentials?.type === "basic"
          ? {
              type: "basic",
              password: state.credit_facade_credentials.password,
              username: state.credit_facade_credentials.username,
            }
          : {
              type: "none",
            };

    return onCreate({
      payto_uri: state.payto_uri!,
      credit_facade_credentials,
      credit_facade_url,
    });
  };
  return (
    <div>
      {importing && <ImportingAccountModal onCancel={()=> {setImporting(false)}} onConfirm={(ac) => {
        state.payto_uri = ac.accountURI
        const u = new URL(ac.infoURL)
        u.password = ""
        if (u.username || u.password) {
          state.credit_facade_credentials = {
            type: "basic",
            password: u.password,
            username: u.username,
          }
          state.repeatPassword = u.password
        }
        u.password = ""
        u.username = ""
        state.credit_facade_url = u.href;
        setImporting(false)
      }}  />}
      <section class="section is-main-section">
        <div class="columns">
          <div class="column" />
          <div class="column is-four-fifths">
            <FormProvider
              object={state}
              valueHandler={setState}
              errors={errors}
            >
              <InputPaytoForm<Entity>
                name="payto_uri"
                label={i18n.str`Account`}
              />
              <Input<Entity>
                name="credit_facade_url"
                label={i18n.str`Account info URL`}
                help="https://bank.com"
                expand
                tooltip={i18n.str`From where the merchant can download information about incoming wire transfers to this account`}
              />
              <InputSelector
                name="credit_facade_credentials.type"
                label={i18n.str`Auth type`}
                tooltip={i18n.str`Choose the authentication type for the account info URL`}
                values={accountAuthType}
                toStr={(str) => {
                  if (str === "none") return "Without authentication";
                  return "Username and password";
                }}
              />
              {state.credit_facade_credentials?.type === "basic" ? (
                <Fragment>
                  <Input
                    name="credit_facade_credentials.username"
                    label={i18n.str`Username`}
                    tooltip={i18n.str`Username to access the account information.`}
                  />
                  <Input
                    name="credit_facade_credentials.password"
                    inputType="password"
                    label={i18n.str`Password`}
                    tooltip={i18n.str`Password to access the account information.`}
                  />
                  <Input
                    name="repeatPassword"
                    inputType="password"
                    label={i18n.str`Repeat password`}
                  />
                </Fragment>
              ) : undefined}
            </FormProvider>

            <div class="buttons is-right mt-5">
              <button
                class="button is-info"
                data-tooltip={i18n.str`Need to complete marked fields`}
                onClick={() => {
                  setImporting(true)
                }}
              >
                <i18n.Translate>Import from bank</i18n.Translate>
              </button>

              {onBack && (
                <button class="button" onClick={onBack}>
                  <i18n.Translate>Cancel</i18n.Translate>
                </button>
              )}
              <AsyncButton
                disabled={hasErrors}
                data-tooltip={
                  hasErrors
                    ? i18n.str`Need to complete marked fields`
                    : "confirm operation"
                }
                onClick={submitForm}
              >
                <i18n.Translate>Confirm</i18n.Translate>
              </AsyncButton>
            </div>
          </div>
          <div class="column" />
        </div>
      </section>
    </div>
  );
}
