/*
 This file is part of GNU Taler
 (C) 2021-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

/**
 *
 * @author Sebastian Javier Marchano (sebasjm)
 */

import {
  HttpStatusCode,
  TalerError,
  TalerMerchantApi,
  assertUnreachable
} from "@gnu-taler/taler-util";
import {
  useTranslationContext
} from "@gnu-taler/web-util/browser";
import { Fragment, VNode, h } from "preact";
import { useState } from "preact/hooks";
import { ErrorLoadingMerchant } from "../../../../components/ErrorLoadingMerchant.js";
import { Loading } from "../../../../components/exception/loading.js";
import { NotificationCard } from "../../../../components/menu/index.js";
import { useSessionContext } from "../../../../context/session.js";
import { useOtpDeviceDetails } from "../../../../hooks/otp.js";
import { Notification } from "../../../../utils/types.js";
import { LoginPage } from "../../../login/index.js";
import { NotFoundPageOrAdminCreate } from "../../../notfound/index.js";
import { CreatedSuccessfully } from "../create/CreatedSuccessfully.js";
import { UpdatePage } from "./UpdatePage.js";

export type Entity = TalerMerchantApi.OtpDevicePatchDetails & WithId;

interface Props {
  onBack?: () => void;
  onConfirm: () => void;
  vid: string;
}
export default function UpdateValidator({
  vid,
  onConfirm,
  onBack,
}: Props): VNode {
  const result = useOtpDeviceDetails(vid);
  const [notif, setNotif] = useState<Notification | undefined>(undefined);
  const [keyUpdated, setKeyUpdated] =
    useState<TalerMerchantApi.OtpDeviceAddDetails | null>(null);
  const { lib } = useSessionContext();
  const { state } = useSessionContext();

  const { i18n } = useTranslationContext();

  if (!result) return <Loading />;
  if (result instanceof TalerError) {
    return <ErrorLoadingMerchant error={result} />;
  }
  if (result.type === "fail") {
    switch (result.case) {
      case HttpStatusCode.NotFound: {
        return <NotFoundPageOrAdminCreate />;
      }
      case HttpStatusCode.Unauthorized: {
        return <LoginPage />
      }
      default: {
        assertUnreachable(result);
      }
    }
  }

  if (keyUpdated) {
    return <CreatedSuccessfully entity={keyUpdated} onConfirm={onConfirm} />;
  }

  return (
    <Fragment>
      <NotificationCard notification={notif} />
      <UpdatePage
        device={{
          id: vid,
          otp_algorithm: result.body.otp_algorithm,
          otp_device_description: result.body.device_description,
          otp_key: "",
          otp_ctr: result.body.otp_ctr,
        }}
        onBack={onBack}
        onUpdate={async (newInfo) => {
          return lib.instance
            .updateOtpDevice(state.token, vid, newInfo)
            .then((d) => {
              if (d.type === "ok") {
                if (newInfo.otp_key) {
                  setKeyUpdated({
                    otp_algorithm: newInfo.otp_algorithm,
                    otp_device_description: newInfo.otp_device_description,
                    otp_device_id: newInfo.id,
                    otp_key: newInfo.otp_key,
                    otp_ctr: newInfo.otp_ctr,
                  });
                } else {
                  onConfirm();
                }
              } else {
                switch(d.case) {
                  case HttpStatusCode.NotFound: {
                    setNotif({
                      message: i18n.str`Could not update template`,
                      type: "ERROR",
                      description: i18n.str`Template id is unknown`,
                    });
                    break;
                  }
                  case HttpStatusCode.Conflict: {
                    setNotif({
                      message: i18n.str`Could not update template`,
                      type: "ERROR",
                      description: i18n.str`The provided information is inconsistent with the current state of the template`,
                    });
                    break;
                  }
                }
              }
            })
            .catch((error) => {
              setNotif({
                message: i18n.str`could not update template`,
                type: "ERROR",
                description: error.message,
              });
            });
        }}
      />
    </Fragment>
  );
}
