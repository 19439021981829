/*
 This file is part of GNU Taler
 (C) 2021-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

/**
 *
 * @author Sebastian Javier Marchano (sebasjm)
 */

import {
  FacadeCredentials,
  HttpStatusCode,
  OperationFail,
  OperationOk,
  PaytoString,
  TalerError,
  TalerMerchantApi,
  TalerRevenueHttpClient,
  assertUnreachable,
  opFixedSuccess,
} from "@gnu-taler/taler-util";
import {
  BrowserFetchHttpLib,
  useTranslationContext
} from "@gnu-taler/web-util/browser";
import { Fragment, VNode, h } from "preact";
import { useState } from "preact/hooks";
import { NotificationCard } from "../../../../components/menu/index.js";
import { useSessionContext } from "../../../../context/session.js";
import { Notification } from "../../../../utils/types.js";
import { CreatePage } from "./CreatePage.js";

export type Entity = TalerMerchantApi.AccountAddDetails;
interface Props {
  onBack?: () => void;
  onConfirm: () => void;
}

export default function CreateValidator({ onConfirm, onBack }: Props): VNode {
  const { lib: api } = useSessionContext();
  const { state } = useSessionContext();
  const [notif, setNotif] = useState<Notification | undefined>(undefined);
  const { i18n } = useTranslationContext();

  return (
    <>
      <NotificationCard notification={notif} />
      <CreatePage
        onBack={onBack}
        onCreate={async (request: Entity) => {
          const revenueAPI = !request.credit_facade_url
            ? undefined
            : new URL("./", request.credit_facade_url);

          if (revenueAPI) {
            const resp = await testRevenueAPI(
              revenueAPI,
              request.credit_facade_credentials,
              request.payto_uri,  
            );
            if (resp instanceof TalerError) {
              setNotif({
                message: i18n.str`Could not add bank account`,
                type: "ERROR",
                description: i18n.str`The request to check the revenue API failed.`,
                details: JSON.stringify(resp.errorDetail, undefined, 2),
              });
              return;
            }
            if (resp.type === "fail") {
              switch (resp.case) {
                case HttpStatusCode.BadRequest: {
                  setNotif({
                    message: i18n.str`Could not add bank account`,
                    type: "ERROR",
                    description: i18n.str`Server replied with "bad request".`,
                  });
                  return;

                }
                case HttpStatusCode.Unauthorized: {
                  setNotif({
                    message: i18n.str`Could not add bank account`,
                    type: "ERROR",
                    description: i18n.str`Unauthorized, try with another credentials.`,
                  });
                  return;

                }
                case HttpStatusCode.NotFound: {
                  setNotif({
                    message: i18n.str`Could not add bank account`,
                    type: "ERROR",
                    description: i18n.str`The endpoint doesn't seems to be a Taler Revenue API`,
                  });
                  return;
                }
                case TestRevenueErrorType.ANOTHER_ACCOUNT: {
                  setNotif({
                    message: i18n.str`Could not add bank account`,
                    type: "ERROR",
                    description: i18n.str`The account info URL returned information from an account which is not the same in the account form: ${resp.detail.hint}`,
                  });
                  return;
                }
                default: {
                  assertUnreachable(resp);
                }
              }
            }
          }

          return api.instance
            .addBankAccount(state.token, request)
            .then(() => {
              onConfirm();
            })
            .catch((error) => {
              setNotif({
                message: i18n.str`could not create account`,
                type: "ERROR",
                description: error.message,
              });
            });
        }}
      />
    </>
  );
}

export enum TestRevenueErrorType {
  ANOTHER_ACCOUNT,
}

export async function testRevenueAPI(
  revenueAPI: URL,
  creds: FacadeCredentials | undefined,
  account: PaytoString,
): Promise<OperationOk<void> | OperationFail<HttpStatusCode.NotFound>
| OperationFail<HttpStatusCode.Unauthorized>
| OperationFail<HttpStatusCode.BadRequest>
| OperationFail<TestRevenueErrorType.ANOTHER_ACCOUNT>
| TalerError> {
  const api = new TalerRevenueHttpClient(
    revenueAPI.href,
    new BrowserFetchHttpLib(),
  );
  const auth =
    creds === undefined
      ? undefined
      : creds.type === "none"
        ? undefined
        : creds.type === "basic"
          ? {
              username: creds.username,
              password: creds.password,
            }
          : undefined;

  try {
    const config = await api.getConfig(auth);

    if (config.type === "fail") {
      return config;
    }

    const history = await api.getHistory(auth);

    if (history.type === "fail") {
      return history;
    }
    if (history.body.credit_account !== account) {
      return {
        type: "fail",
        case: TestRevenueErrorType.ANOTHER_ACCOUNT,
        detail: {
          code: 1,
          hint: history.body.credit_account
        },
      };
    }
  } catch (err) {
    if (err instanceof TalerError) {
      return err;
      // return {
      //   type: "fail",
      //   case: TestRevenueErrorType.GENERIC_ERROR,
      //   detail: err.errorDetail,
      // };
    }
  }

  return opFixedSuccess(undefined);
}
