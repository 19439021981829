/*
 This file is part of GNU Taler
 (C) 2021-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

/**
 *
 * @author Sebastian Javier Marchano (sebasjm)
 */

import { HttpStatusCode, TalerError, TalerMerchantApi, assertUnreachable } from "@gnu-taler/taler-util";
import { Fragment, VNode, h } from "preact";
import { useState } from "preact/hooks";
import { ErrorLoadingMerchant } from "../../../../components/ErrorLoadingMerchant.js";
import { Loading } from "../../../../components/exception/loading.js";
import { NotificationCard } from "../../../../components/menu/index.js";
import { useSessionContext } from "../../../../context/session.js";
import { useInstanceDetails } from "../../../../hooks/instance.js";
import { useInstanceProducts } from "../../../../hooks/product.js";
import { Notification } from "../../../../utils/types.js";
import { LoginPage } from "../../../login/index.js";
import { NotFoundPageOrAdminCreate } from "../../../notfound/index.js";
import { CreatePage } from "./CreatePage.js";

export type Entity = {
  request: TalerMerchantApi.PostOrderRequest;
  response: TalerMerchantApi.PostOrderResponse;
};
interface Props {
  onBack?: () => void;
  onConfirm: (id: string) => void;
}
export default function OrderCreate({
  onConfirm,
  onBack,
}: Props): VNode {
  const { lib } = useSessionContext();
  const [notif, setNotif] = useState<Notification | undefined>(undefined);
  const { state } = useSessionContext();
  const detailsResult = useInstanceDetails();
  const inventoryResult = useInstanceProducts();

  if (!detailsResult) return <Loading />
  if (detailsResult instanceof TalerError) {
    return <ErrorLoadingMerchant error={detailsResult} />
  }
  if (detailsResult.type === "fail") {
    switch (detailsResult.case) {
      case HttpStatusCode.Unauthorized: {
        return <LoginPage />
      }
      case HttpStatusCode.NotFound: {
        return <NotFoundPageOrAdminCreate />;
      }
      default: {
        assertUnreachable(detailsResult);
      }
    }
  }
  if (!inventoryResult) return <Loading />
  if (inventoryResult instanceof TalerError) {
    return <ErrorLoadingMerchant error={inventoryResult} />
  }
  if (inventoryResult.type === "fail") {
    switch (inventoryResult.case) {
      case HttpStatusCode.NotFound: {
        return <NotFoundPageOrAdminCreate />;
      }
      case HttpStatusCode.Unauthorized: {
        return <LoginPage />
      }
      default: {
        assertUnreachable(inventoryResult);
      }
    }
  }

  return (
    <Fragment>
      <NotificationCard notification={notif} />

      <CreatePage
        onBack={onBack}
        onCreate={(request: TalerMerchantApi.PostOrderRequest) => {
          lib.instance.createOrder(state.token, request)
            .then((r) => {
              if (r.type === "ok") {
                return onConfirm(r.body.order_id)
              } else {
                setNotif({
                  message: "could not create order",
                  type: "ERROR",
                });
              }
            })
            .catch((error) => {
              setNotif({
                message: "could not create order",
                type: "ERROR",
                description: error.message,
              });
            });
        }}
        instanceConfig={detailsResult.body}
        instanceInventory={inventoryResult.body}
      />
    </Fragment>
  );
}
